import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import uuidReducer from "./features/uuidSlice";
import snackbarReducer from "./features/snackbarSlice";
import disclaimerReducer from "./features/disclaimerSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {thunk} from "redux-thunk";

const reducers = combineReducers({
    auth: authReducer,
    uuid: uuidReducer,
    snackbar: snackbarReducer,
    disclaimer: disclaimerReducer
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ["_persist"],
                ignoredActions: [
                    "persist/PERSIST",
                    "persist/REHYDRATE",
                    "persist/REGISTER",
                ],
            },
        }).concat(thunk),
});