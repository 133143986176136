import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  position: relative;
  width: 940px;
  height: 800px;
  border-radius: 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;

  @media (max-width: 1024px) {
    width: 75%;
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 500px;
    padding: 1rem;
  }

  svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    fill: gray;
    cursor: pointer;
  }
`;

const OfferHeading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  h2 {
    font-size: 2rem;

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const OfferTitle = styled.p`
  font-size: 1.4rem;
  font-weight: 900;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const OfferAdd = styled.div`
  width: 100%;
  height: 500px;
  margin: 1rem auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    width: 90%;
    height: 180px;
  }
`;

const ButtonsDiv = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;

  p {
    font-size: 1rem;
    color: #666666;
    width: 100%;
    text-align: center;
    padding: 1rem 0 0 0;
    cursor: pointer;
  }
`;

const ModalButton = styled.button`
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: ${({ isBack }) => (isBack ? '#ccc' : '#8ac926')};
  color: white;
  width: 100%;
  padding: 14px;
  font-weight: 900;
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.8rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isBack }) =>
    isBack &&
    `
        &::before {
            //content: '<';
            position: absolute;
            left: 10px;
            font-size: .9rem;
        }
    `}

  ${({ isBack }) =>
    !isBack &&
    `
        &::after {
            //content: '>';
            position: absolute;
            right: 10px;
            font-size: .9rem;
        }
    `}
`;

const Modal = ({ forwardRef, closeModal, offerData }) => {
  const uuid = useSelector((state) => state?.uuid?.uuid);
  const auth = useSelector((state) => state?.auth?.user);

  console.log(auth);

  const handleRequest = async (e) => {
    e.preventDefault();
    const newTab = window.open(offerData?.link, '_blank');

    try {
      const headers = auth?.token
        ? { Authorization: `Bearer ${auth.token}` }
        : {};

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/offer/redeem/${offerData?.id}`,
        {
          uuid: uuid,
        },
        {
          headers,
        },
      );

      if (newTab) {
        newTab.location.href = offerData?.link;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ModalOverlay ref={forwardRef} onClick={closeModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <svg
          onClick={closeModal}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
        <OfferHeading>
          <h2>You're Now Leaving</h2>
          <p>welcomehomeowner.com</p>
        </OfferHeading>
        <OfferAdd
          style={{
            backgroundImage: `url(${offerData?.image_url})`,
          }}
        />

        <ButtonsDiv>
          <ModalButton onClick={handleRequest}>Continue to offer</ModalButton>

          <p isBack onClick={closeModal}>
            {' '}
            Close{' '}
          </p>
        </ButtonsDiv>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
