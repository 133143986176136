import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FloatingDisclaimer from './FloatingDisclaimer';
import { setSnackbar } from '../redux/features/snackbarSlice';
import { setDisclaimer } from '../redux/features/disclaimerSlice';

const FooterContainer = styled.div`
  background-color: #eee;
  position: relative;
  padding: 80px 160px;

  @media (max-width: 1180px) {
    padding: 60px 80px;
  }

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const FooterTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;

  @media (max-width: 1180px) {
    display: block;
  }

  img {
    width: 350px;
    height: 140px;
    cursor: pointer;

    @media (max-width: 1430px) {
      width: 200px;
      height: 80px;
    }
  }
`;

const FooterLinksAndLogo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: top;

  @media (max-width: 1180px) {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const FooterTopSectionLinksRow = styled.div`
  display: flex;
  gap: 3rem;
  padding-top: 4rem;

  @media (max-width: 1430px) {
    padding-top: 1.5rem;
  }

  @media (max-width: 1180px) {
    width: 100%;
    margin-top: 2rem;
    text-align: center;
  }

  p {
    font-size: 1.05rem;
    font-weight: 600;
    cursor: pointer;

    @media (max-width: 1180px) {
      font-size: 0.9rem;
      width: 100%;
    }
  }
`;

const FooterTopSectionSubscribeContainer = styled.div`
  width: 40%;

  @media (max-width: 1180px) {
    width: 100%;
  }

  form {
    margin: 0;
    padding: 0;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;

    @media (max-width: 1180px) {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  input {
    padding: 12px;
    border: 1px solid lightgray;
    border-radius: 6px;
    width: 100%;

    @media (max-width: 1180px) {
      width: 100%;
      margin: 0 auto;
    }
  }

  button {
    outline: none;
    border-radius: 8px;
    border: 2px solid #d4f4bb;
    background: linear-gradient(to top, #7ac143, #94e656);
    color: white;
    width: 100%;
    padding: 12px;
    font-weight: 800;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    @media (max-width: 1350px) {
      padding: 10px;
    }

    @media (max-width: 1180px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  p {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: rgba(0, 0, 0, 0.6);

    @media (max-width: 768px) {
      margin-top: 2rem;
    }

    a {
      color: inherit;
    }
  }
`;

const FooterTopSectionSubscribeInputButton = styled.div`
  display: block;
  width: 100%;

  @media (max-width: 768px) {
    display: block;
  }
`;

const FooterTopSectionDisclaimer = styled.div`
  display: block;
  font-size: 0.75rem;
  color: gray;
  width: 100%;
  padding: 1rem 0.25rem;

  @media (max-width: 1180px) {
    margin-top: 0;
    width: 100%;
    text-align: center;
  }

  p {
    @media (max-width: 1180px) {
      text-align: left !important;
    }
  }
`;

const FooterBottomSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 3rem;

  p {
    font-size: 0.75rem;
    color: #000;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  div {
    display: flex;
    gap: 1rem;
  }
`;

const FooterBottomSectionLinks = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    font-size: 0.75rem;
    color: gray;
    text-decoration: underline;
    cursor: pointer;
    text-align: left;

    a {
      color: #000;
      text-decoration: underline;
    }
  }
`;

const ErrorText = styled.p`
  font-size: 0.85rem;
  color: red !important;
  margin-top: 1rem;
  text-align: center;
  font-weight: 600;
`;

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disclaimer = useSelector((state) => state.disclaimer);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const uuid = useSelector((state) => state?.uuid?.uuid);

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subscribe`,
        {
          email: email,
          uuid: uuid,
        },
      );
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'Subscribed succesfully!',
          type: 'success',
        }),
      );
    } catch (error) {
      setLoading(false);
      setError(error?.response?.data?.message);
    }
  };
  return (
    <FooterContainer>
      <FooterTopSection>
        <FooterLinksAndLogo>
          <img
            onClick={() => handleNavigate('/')}
            alt="Website Logo"
            src="/welcome-home_logo.png"
          />

          <FooterTopSectionLinksRow>
            <p onClick={() => handleNavigate('/')}>Offers</p>
            <p onClick={() => handleNavigate('/about')}>About</p>
            <p onClick={() => handleNavigate('/contact')}>Contact</p>
          </FooterTopSectionLinksRow>
        </FooterLinksAndLogo>

        {disclaimer?.isVisible && !disclaimer?.isAgreed ? (
          <FooterTopSectionSubscribeContainer>
            <h3>Subscribe for Offer Alerts</h3>

            <form onSubmit={handleSubmit}>
              <FooterTopSectionSubscribeInputButton>
                <input
                  disabled
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email Address"
                />

                <FooterTopSectionDisclaimer>
                  <p>
                    By clicking Select & Agree, I am providing my ESIGN
                    signature and express written consent to permit Media
                    Resource Group LLC the owner of the Welcomehomeowner.com
                    website to contact me about the services and/or products
                    they offer at the email and phone number I provided above
                    including through the use of automated technology, AI
                    generative voice, and prerecorded and/or artificial voice
                    messages. I understand and acknowledge that my consent is
                    not required to obtain any product or service, and if I wish
                    to be connected with Media Resource Group LLC regarding the
                    Welcomehomeowner.com website, without providing my
                    information, I can call{' '}
                    <a href="tel:2396313553">(239)-631-3553</a>. I understand
                    that Media Resource Group LLC calls may be recorded.
                  </p>
                </FooterTopSectionDisclaimer>

                <button disabled>SELECT & AGREE</button>
              </FooterTopSectionSubscribeInputButton>

              {error && <ErrorText>{error}</ErrorText>}
            </form>
          </FooterTopSectionSubscribeContainer>
        ) : !disclaimer?.isVisible && disclaimer?.isAgreed ? (
          <FooterTopSectionSubscribeContainer>
            <h3>Subscribe for Offer Alerts</h3>

            <form onSubmit={handleSubmit}>
              <FooterTopSectionSubscribeInputButton>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email Address"
                />

                <FooterTopSectionDisclaimer>
                  <p>
                    By clicking Select & Agree, I am providing my ESIGN
                    signature and express written consent to permit Media
                    Resource Group LLC the owner of the Welcomehomeowner.com
                    website to contact me about the services and/or products
                    they offer at the email and phone number I provided above
                    including through the use of automated technology, AI
                    generative voice, and prerecorded and/or artificial voice
                    messages. I understand and acknowledge that my consent is
                    not required to obtain any product or service, and if I wish
                    to be connected with Media Resource Group LLC regarding the
                    Welcomehomeowner.com website, without providing my
                    information, I can call{' '}
                    <a href="tel:2396313553">(239)-631-3553</a>. I understand
                    that Media Resource Group LLC calls may be recorded.
                  </p>
                </FooterTopSectionDisclaimer>

                <button>SELECT & AGREE</button>
              </FooterTopSectionSubscribeInputButton>

              {error && <ErrorText>{error}</ErrorText>}
            </form>
          </FooterTopSectionSubscribeContainer>
        ) : null}

        {!disclaimer?.isAgreed && !disclaimer?.isVisible && (
          <FooterTopSectionSubscribeContainer>
            <FooterTopSectionDisclaimer>
              <p
                style={{
                  textAlign: 'right',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  lineHeight: '28px',
                }}
              >
                Call now to get started!{' '}
                <a href="tel:18009299520">1-800-929-9520</a> Or{' '}
                <span
                  onClick={() =>
                    dispatch(
                      setDisclaimer({ isVisible: true, isAgreed: false }),
                    )
                  }
                  style={{
                    fontWeight: 600,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Click here
                </span>{' '}
                to fill out the form
              </p>
            </FooterTopSectionDisclaimer>
          </FooterTopSectionSubscribeContainer>
        )}
      </FooterTopSection>

      <FooterBottomSection>
        <p>
          Copyright {new Date().getFullYear()} Welcome Home. All rights
          reserved.
        </p>

        <FooterBottomSectionLinks>
          <p>
            <a
              target="_blank"
              rel="noreferer"
              href="https://www.mrginc.com/terms-conditions"
            >
              Terms Of Service
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferer"
              href="https://www.mrginc.com/privacy-policy"
            >
              Privacy Policy
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferer"
              href="https://www.mrginc.com/do-not-sell-my-personal-information"
            >
              Do Not Sell or Share My Personal Information
            </a>
          </p>
        </FooterBottomSectionLinks>
      </FooterBottomSection>

      {disclaimer?.isVisible && <FloatingDisclaimer />}
    </FooterContainer>
  );
};

export default Footer;
